<template>
  <div id="lCenter">
    <div class="bg-color">
      <dv-decoration-7
        style="width: 150px; height: 30px; font-weight: bold; font-size: 22px"
        >布控类型</dv-decoration-7
      >
      <div class="main">
        <dv-border-box-10
          :color="['blue', '#ADD8E6']"
          style="width: 180px; height: 100px"
        >
          <div class="main-item">
            <p class="p1">—</p>
            <p class="p2">重点人员</p>
          </div>
        </dv-border-box-10>
        <dv-border-box-10
          :color="['orange', '#ffb46c']"
          style="width: 180px; height: 100px;margin-left:15px;"
        >
          <div class="main-item">
            <p class="p1">—</p>
            <p class="p2">重点四无</p>
          </div>
        </dv-border-box-10>
        <dv-border-box-10
          :color="['red', '#FFB6C1']"
          style="width: 180px; height: 100px;margin-top:20px;"
        >
          <div class="main-item">
            <p class="p1">—</p>
            <p class="p2">全国在逃人员</p>
          </div>
        </dv-border-box-10>
      </div>
      <!-- 中间 -->
      <div style="margin-top: 30px">
        <dv-decoration-7
          style="width: 150px; height: 30px; font-weight: bold; font-size: 22px"
          >预警处置</dv-decoration-7
        >
        <div style="margin-top: 15px">
          <CenterLeft1Chart></CenterLeft1Chart>
        </div>
      </div>
      <!-- 下面 -->
      <div>
        <dv-decoration-7
          style="width: 150px; height: 30px; font-weight: bold; font-size: 22px"
          >预警统计</dv-decoration-7
        >
        <div class="yj-box">
          <div class="yj-item">
            <dv-border-box-9
              style="width: 160px; height: 80px"
              :color="['yellow']"
            >
              <div>
                <p class="yj-p1">163</p>
                <p class="yj-p2" style="color: yellow">历史</p>
              </div>
            </dv-border-box-9>
          </div>
          <div class="yj-item" style="margin-left: 50px">
            <dv-border-box-9
              style="width: 160px; height: 80px"
              :color="['green']"
            >
              <div>
                <p class="yj-p1">112</p>
                <p class="yj-p2" style="color: green">本年</p>
              </div>
            </dv-border-box-9>
          </div>
          <div class="yj-item">
            <dv-border-box-9
              style="width: 160px; height: 80px"
              :color="['orange']"
            >
              <div>
                <p class="yj-p1">23</p>
                <p class="yj-p2" style="color: orange">当月</p>
              </div>
            </dv-border-box-9>
          </div>
          <div class="yj-item" style="margin-left: 50px; margin-top: 20px">
            <dv-border-box-9
              style="width: 160px; height: 80px"
              :color="['purple']"
            >
              <div>
                <p class="yj-p1">5</p>
                <p class="yj-p2" style="color: purple">当日</p>
              </div>
            </dv-border-box-9>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CenterLeft1Chart from '@/components/echart/centerLeft/centerLeft1Chart'

export default {
  components: {
    CenterLeft1Chart,
  },
  data() {
    return {
      options: {},
    }
  },
}
</script>

<style lang="scss" scoped>
$box-height: 1080px;
$box-width: 410px;

#lCenter {
  padding: 16px;
  height: $box-height;
  width: $box-width;
  border-radius: 5px;

  .bg-color {
    height: $box-height - 30px;
    border-radius: 10px;

    .main {
      margin-top: 15px;
      width: 410px;
      height: 220px;
      display: flex;
      flex-wrap: wrap;

      .main-item {
        padding-top: 20px;
        text-align: center;
        font-weight: bold;

        .p1 {
          font-size: 20px;
        }

        .p2 {
          font-size: 18px;
          margin-top: 20px;
        }
      }
    }

    .yj-box {
      margin-top: 15px;

      .yj-item {
        display: inline-block;
        text-align: center;

        .yj-p1 {
          padding-top: 15px;
          font-size: 20px;
          font-weight: bold;
        }

        .yj-p2 {
          margin-top: 10px;
          font-size: 20px;
          font-weight: bold;
        }
      }
    }
  }
}
</style>
