<template>
  <div id="rightCenter">
    <div class="bg-color">
      <dv-decoration-7
        style="width: 150px; height: 30px; font-weight: bold; font-size: 22px"
        >今日预警</dv-decoration-7
      >
      <!-- <div class="tab-box">
        <div>
          <div style="font-size: 18px; font-weight: bold">公交</div>
          <dv-decoration-2 style="width: 40px; height: 10px" />
        </div>
        <div>
          <div style="font-size: 18px; font-weight: bold">长途</div>
          <dv-decoration-2 style="width: 40px; height: 10px" />
        </div>
        <div>
          <div style="font-size: 18px; font-weight: bold">地铁</div>
          <dv-decoration-2 style="width: 40px; height: 10px" />
        </div>
      </div> -->
      <div>
        <dv-capsule-chart
          :config="config"
          style="width: 420px; height: 245px"
        />
      </div>

      <!-- 中间 -->
      <dv-decoration-7
        style="
          width: 150px;
          height: 30px;
          font-weight: bold;
          font-size: 22px;
          margin-top: 20px;
        "
        >预警汇总</dv-decoration-7
      >
      <div style="margin-top: 15px">
        <Echart :options="options" height="300px" width="420px"></Echart>
      </div>

      <!-- 底部 -->
      <dv-decoration-7
        style="width: 150px; height: 30px; font-weight: bold; font-size: 22px"
        >预警排名</dv-decoration-7
      >
      <div style="margin-top: 15px;width: 420px; height: 200px;overflow-y: hidden;">
        <dv-scroll-board
          :config="config1"
          style="width: 420px; height: 350px;"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Echart from '@/common/echart'

export default {
  components: {
    Echart,
  },
  data() {
    return {
      config: {
        data: [
          {
            name: '蒸湘区',
            value: 167,
          },
          {
            name: '石鼓区',
            value: 67,
          },
          {
            name: '珠晖区',
            value: 123,
          },
          {
            name: '雁峰区',
            value: 55,
          },
          {
            name: '高新区',
            value: 98,
          },
        ],
      },
      config1: {
        data: [
          ['奢漫轻奢公寓', '<span style="color:#67e0e3;">3</span>'],
          ['泊曼轻居酒店', '<span style="color:#67e0e3;">5</span>'],
          ['加曼金河湾店', '<span style="color:#67e0e3;">7</span>'],
          ['加曼酒店(融冠亲城店)', '<span style="color:#67e0e3;">9</span>'],
          ['一念间民宿', '<span style="color:#67e0e3;">11</span>'],
          ['头号玩家电竞酒店万达店', '<span style="color:#67e0e3;">1</span>'],
          ['乐星辰私享家影视公寓酒店', '<span style="color:#67e0e3;">6</span>'],
          ['前曼酒店（冠都现代城店）', '<span style="color:#67e0e3;">7</span>'],
          ['红馆民宿（南岳总店）', '<span style="color:#67e0e3;">4</span>'],
          ['简禾民宿', '<span style="color:#67e0e3;">3</span>'],
          ['吾绯设计师民宿（南岳庙东店）','<span style="color:#67e0e3;">2</span>'],
          ['夏平甜蜜旅馆', '<span style="color:#67e0e3;">12</span>'],
          ['头号玩家电竞酒店南华店', '<span style="color:#67e0e3;">11</span>'],
          ['乐星辰公寓酒店', '<span style="color:#67e0e3;">9</span>'],
          ['吾绯咖啡茶饮清吧民宿（大庙店）', '<span style="color:#67e0e3;">5</span>'],
          ['加曼酒店(衡阳市第一中学店）', '<span style="color:#67e0e3;">3</span>'],
          ['加曼船山店', '<span style="color:#67e0e3;">8</span>'],
          ['清韵民宿', '<span style="color:#67e0e3;">10</span>'],
          ['熙钰宾馆', '<span style="color:#67e0e3;">1</span>']
        ],
        rowNum: 7, //表格行数
        columnWidth: [320,100],
        oddRowBGC: '#0f1325', //奇数行
        evenRowBGC: '#171c33', //偶数行
      },
      options: {},
    }
  },
  methods: {
    drawChart() {
      this.options = {
        legend: {},
        tooltip: {},
        dataset: {
          dimensions: ['product', '大客流预警', '人脸预警', '高频入住预警', '未成年人预警'],
          source: [
            {
              product: '1月',
              大客流预警: 3,
              人脸预警: 2,
              高频入住预警: 1,
              未成年人预警: 1,
            },
            {
              product: '2月',
              大客流预警: 5,
              人脸预警: 3,
              高频入住预警: 3,
              未成年人预警: 2,
            },
            {
              product: '3月',
              大客流预警: 6,
              人脸预警: 4,
              高频入住预警: 4,
              未成年人预警: 0,
            },
            {
              product: '4月',
              大客流预警: 6,
              人脸预警: 3,
              高频入住预警: 1,
              未成年人预警: 3,
            },
            {
              product: '5月',
              大客流预警: 4,
              人脸预警: 0,
              高频入住预警: 3,
              未成年人预警: 1,
            },
            {
              product: '6月',
              大客流预警: 5,
              人脸预警: 5,
              高频入住预警: 3,
              未成年人预警: 1,
            },
            {
              product: '7月',
              大客流预警: 7,
              人脸预警: 5,
              高频入住预警: 4,
              未成年人预警: 2,
            },
            {
              product: '8月',
              大客流预警: 5,
              人脸预警: 2,
              高频入住预警: 1,
              未成年人预警: 1,
            },
          ],
        },
        xAxis: { type: 'category' },
        yAxis: {
        },
        grid: {
            left: '11%',
          },
        series: [
          { type: 'bar' },
          { type: 'bar' },
          { type: 'bar' },
          { type: 'bar' },
        ],
      }
    },
  },
  mounted() {
    this.drawChart()
  },
}
</script>

<style lang="scss" scoped>
$box-height: 360px;
$box-width: 420px;

#rightCenter {
  padding: 16px;
  min-height: $box-height;
  width: $box-width;
  border-radius: 5px;

  .bg-color {
    height: $box-height - 30px;
    border-radius: 10px;

    .tab-box {
      margin-top: 20px;
      display: flex;
      justify-content: space-around;
    }
  }
}
</style>
