<template>
  <div>
    <Chart :cdata="cdata" />
  </div>
</template>

<script>
import Chart from './chart.vue';
export default {
  data () {
    return {
      cdata: [
        { value: 300, name: '核查在逃' },
        { value: 735, name: '核查撒网' },
        { value: 580, name: '核查非本人' },
        { value: 484, name: '正在核查' },
        { value: 2000, name: '情况正常' }
      ]
    }
  },
  components: {
    Chart,
  },
  mounted () {
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
</style>