<template>
  <div id="center">
    <div class="middle_top">
      <div class="middle_top_list">
        <div>
          <img
            src="../../assets/img/icon1.png"
            style="width: 48px; height: 48px; vertical-align: middle"
          />
        </div>
        <div style="margin-left: 10px">
          <div class="list_title">1</div>
          <div class="list_num">
            <span class="counter-value">本日访客预警数</span>
          </div>
        </div>
      </div>
      <div class="middle_top_list">
        <div>
          <img
            src="../../assets/img/icon4.png"
            style="width: 48px; height: 48px; vertical-align: middle"
          />
        </div>
        <div style="margin-left: 10px">
          <div class="list_title">18</div>
          <div class="list_num">
            <span class="counter-value">本月访客预警数</span>
          </div>
        </div>
      </div>
      <div class="middle_top_list">
        <div>
          <img
            src="../../assets/img/icon3.png"
            style="width: 48px; height: 48px; vertical-align: middle"
          />
        </div>
        <div style="margin-left: 10px">
          <div class="list_title">67</div>
          <div class="list_num">
            <span class="counter-value">本年访客预警数</span>
          </div>
        </div>
      </div>
    </div>

    <div style="margin-top: 15px">
      <dv-decoration-3 style="width: 800px; height: 20px" />
    </div>
    <!-- 中间 -->
    <div class="bg-box" style="margin-top: 15px">
      <div class="bgImg"></div>
      <div class="bgImg1"></div>
      <div class="bgImg2"></div>
    </div>
    <!-- 底部 -->
    <div class="bottom-box">
      <div class="left-line">
        <dv-decoration-2 style="width: 335px; height: 5px" />
      </div>
      <div class="left-line1">
        <dv-decoration-2 style="width: 335px; height: 5px" />
      </div>
      <div class="left-line2">
        <dv-decoration-2 :reverse="true" style="width: 5px; height: 100px" />
      </div>
      <div class="left-line3">
        <dv-decoration-2 :reverse="true" style="width: 5px; height: 100px" />
      </div>
      <div class="left-radius">
        <div class="leftradius-box">
          <div style="margin-top: 40px">
            <img
              src="../../assets/img/h-gongneng.png"
              style="width: 30px; height: 30px; vertical-align: middle"
            /><span style="margin-left: 10px;">大客流预警</span><span class="span-text">2</span>
          </div>
          <div style="margin-top: 60px">
            <img
              src="../../assets/img/h-wode.png"
              style="width: 30px; height: 30px; vertical-align: middle"
            />
            <span style="margin-left: 10px;">人脸预警</span><span class="span-text">2</span>
          </div>
        </div>
      </div>
      <div class="center-radius">
        <dv-decoration-9
          style="
            width: 200px;
            height: 200px;
            font-weight: bold;
            font-size: 24px;
            color: yellow;
          "
          >5</dv-decoration-9
        >
      </div>
      <div class="right-radius">
        <div class="rightradius-box">
          <div style="margin-top: 40px">
            <img
              src="../../assets/img/h-fenxiang.png"
              style="width: 30px; height: 30px; vertical-align: middle"
            />
            <span style="margin-left: 10px;">高频入住预警</span><span class="span-text">1</span>
          </div>
          <div style="margin-top: 60px">
            <img
              src="../../assets/img/h-wendang.png"
              style="width: 30px; height: 30px; vertical-align: middle"
            />
            <span style="margin-left: 10px;">未成年人预警</span><span class="span-text">0</span>
          </div>
        </div>
      </div>
      <div class="right-line">
        <dv-decoration-2 style="width: 335px; height: 5px" />
      </div>
      <div class="right-line1">
        <dv-decoration-2 style="width: 335px; height: 5px" />
      </div>
      <div class="right-line2">
        <dv-decoration-2 :reverse="true" style="width: 5px; height: 100px" />
      </div>
      <div class="right-line3">
        <dv-decoration-2 :reverse="true" style="width: 5px; height: 100px" />
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
$box-width: 800px;
$box-height: 1080px;

@keyframes rotate1-data-v-dcbae68a {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  to {
    transform: translate(-50%, -50%) rotate(1turn);
  }
}

@keyframes rotate2-data-v-dcbae68a {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  to {
    transform: translate(-50%, -50%) rotate(-1turn);
  }
}

#center {
  padding: 16px;
  min-height: $box-height;
  min-width: $box-width;
  border-radius: 5px;

  .bg-box {
    position: relative;
    height: 504px;
    width: 504px;

    .bgImg {
      position: absolute;
      top: 25px;
      left: 150px;
      height: 500px;
      width: 500px;
      background: url(../../assets/img/page1.png);
      background-size: 100% 100%;
      opacity: 0.3;
    }

    .bgImg1 {
      position: absolute;
      top: 275px;
      left: 400px;
      height: 600px;
      width: 600px;
      background: url(../../assets/img/page2.png);
      background-size: 100% 100%;
      animation: rotate1-data-v-dcbae68a 15s linear infinite;
      opacity: 0.6;
    }

    .bgImg2 {
      position: absolute;
      top: 275px;
      left: 400px;
      height: 542px;
      width: 542px;
      background: url(../../assets/img/page3.png);
      background-size: 100% 100%;
      opacity: 0.8;
      animation: rotate2-data-v-dcbae68a 15s linear infinite;
    }
  }

  .bg-color-black {
    height: $box-height - 30px;
    border-radius: 10px;
  }

  .middle_top {
    width: 100%;
    display: flex;
    justify-content: space-around;
  }

  .middle_top_list {
    display: flex;
    align-items: center;
    text-align: left;
    color: #4adefe;
  }

  .list_title {
    font-size: 26px;
    font-weight: bold;
  }

  .list_num {
    margin-top: 5px;
    font-size: 20px;
  }

  .list_num span {
    font-size: 16px;
    color: white;
  }

  .bottom-box {
    padding-top: 70px;

    .left-radius {
      width: 400px;
      height: 200px;
      background-image: radial-gradient(
        1px at right,
        #050610 120px,
        rgb(3, 133, 182, 0.1)
      );

      .leftradius-box {
        margin-left: 20px;
        margin-top: 20px;
        color: white;
        font-size: 20px;
        font-weight: bold;

        .span-text {
          margin-left: 20px;
        }
      }
    }

    .center-radius {
      position: absolute;
      bottom: 50px;
      left: 741px;
    }

    .right-radius {
      width: 400px;
      height: 200px;
      background-image: radial-gradient(
        1px at left,
        #050610 120px,
        rgb(3, 133, 182, 0.1)
      );

      .rightradius-box {
        margin-left: 140px;
        margin-top: 20px;
        color: white;
        font-size: 20px;
        font-weight: bold;

        .span-text {
          margin-left: 20px;
        }
      }
    }

    .left-line {
      position: absolute;
      bottom: 50px;
      left: 442px;
    }

    .left-line1 {
      position: absolute;
      bottom: 248px;
      left: 442px;
    }

    .left-line2 {
      position: absolute;
      bottom: 150px;
      left: 439px;
    }

    .left-line3 {
      transform: rotate(180deg);
      position: absolute;
      bottom: 51px;
      left: 440px;
    }

    .right-line {
      transform: rotate(180deg);
      position: absolute;
      bottom: 48px;
      right: 678px;
    }

    .right-line1 {
      transform: rotate(180deg);
      position: absolute;
      bottom: 248px;
      right: 678px;
    }

    .right-line2 {
      position: absolute;
      bottom: 150px;
      right: 676px;
    }

    .right-line3 {
      transform: rotate(180deg);
      position: absolute;
      bottom: 51px;
      right: 676px;
    }
  }
}
</style>
