<template>
  <div id="rCenterone">
    <div class="bg-color">
      <div id="div2">
        <div id="wrap">
          <div class="item">
            <img
              src="https://hyga-1251463813.cos.ap-shanghai.myqcloud.com/upload/20240827/tmp_3a0d5274cdd8a8fd4d00f45993f7640bdace1159729f0204_1724764291620.jpg"
            />
          </div>
          <div class="item">
            <img
              src="https://hyga-1251463813.cos.ap-shanghai.myqcloud.com/upload/20240829/tmp_6917a0df18efe634477f4fabad63a9e5_1724900119900.jpg"
            />
          </div>
          <div class="item">
            <img
              src="https://hyga-1251463813.cos.ap-shanghai.myqcloud.com/upload/20240829/tmp_da63e00dd95ec4cc711ce3729e860980_1724900061086.jpg"
            />
          </div>
          <div class="item">
            <img
              src="https://hyga-1251463813.cos.ap-shanghai.myqcloud.com/upload/20240828/tmp_dd4f4b4778703d2a8cfb0795bd5e0c201bd32046dea2d042_1724820887029.jpg"
            />
          </div>
          <div class="item">
            <img
              src="https://hyga-1251463813.cos.ap-shanghai.myqcloud.com/upload/20240820/tmp_3df5f682085dd5fd0efa57a094d6eeb9_1724157122471.jpg"
            />
          </div>
          <div class="item">
            <img
              src="https://hyga-1251463813.cos.ap-shanghai.myqcloud.com/upload/20240820/tmp_567bc5c893b8a815fb598138b5c79035_1724161630865.jpg"
            />
          </div>
          <div class="item">
            <img
              src="https://hyga-1251463813.cos.ap-shanghai.myqcloud.com/upload/20240816/tmp_c9a6137c09a3c4de0b689c39c731303e_1723807206917.jpg"
            />
          </div>
          <div class="item">
            <img
              src="https://hyga-1251463813.cos.ap-shanghai.myqcloud.com/upload/20240816/11111_1723801005739.jpg"
            />
          </div>
          <div class="item">
            <img
              src="https://hyga-1251463813.cos.ap-shanghai.myqcloud.com/upload/20240809/tmp_c1f339c3c7d3fa7f28326c626ca57ad383b7b7eb84f57b39_1723204434954.jpg"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      timer: '',
    }
  },
  mounted() {
    this.autoPlay()
  },
  methods: {
    autoPlay() {
      //自动播放
      var speed = 1 //初始化速度

      var oWrap = document.getElementById('wrap') //获取外层盒子

      oWrap.innerHTML += oWrap.innerHTML //图片内容*2，播放的时候障眼法，不会断章
      var oImgs = document.getElementsByClassName('item') //获取所有的item
      oWrap.style.height = oImgs.length * 220 + 'px' //设置oWrap的高度使图片可以放下
      //220 是高度，依据你图片的高度来定

      this.timer = setInterval(function () {
        //设置定时器
        if (oWrap.offsetTop < -(oWrap.offsetHeight / 2)) {
          //当前四张图移除的时候，重新开始移
          oWrap.style.top = 0
        }
        oWrap.style.top = oWrap.offsetTop - speed + 'px'
      }, 10)
    },
    pasue() {
      //鼠标移入图片暂停轮播
      clearInterval(this.timer)
    },
    play() {
      //鼠标移除图片继续轮播
      this.autoPlay()
    },
  },
}
</script>

<style lang="scss" scoped>
$box-height: 1080px;
$box-width: 200px;

#rCenterone {
  padding: 16px;
  min-height: $box-height;
  width: $box-width;
  border-radius: 5px;
}

#div2 {
  width: 200px;
  height: 1080px;
  position: relative;
  overflow: hidden;
}

#wrap {
  position: absolute;
  left: 0;
  top: 0;
  overflow: hidden;
}

#wrap div {
  float: left;
  width: 200px;
  height: 200px;
  margin-top: 20px;
}

.item img {
  width: 150px;
  height: 200px;
}
</style>
