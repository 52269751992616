<template>
  <div id="index" ref="appRef">
    <div class="bg">
      <dv-loading v-if="loading">Loading...</dv-loading>
      <div v-else class="host-body">
        <div class="d-flex jc-center">
          <dv-decoration-10 class="dv-dec-10" />
          <div class="d-flex jc-center">
            <dv-decoration-8 class="dv-dec-8" :color="decorationColor" />
            <div class="title">
              <span class="title-text">网约房治安管理智能预警</span>
              <dv-decoration-6
                class="dv-dec-6"
                :reverse="true"
                :color="['#50e3c2', '#67a1e5']"
              />
            </div>
            <dv-decoration-8
              class="dv-dec-8"
              :reverse="true"
              :color="decorationColor"
            />
          </div>
          <dv-decoration-10 class="dv-dec-10-s" />
        </div>

        <!-- 第二行 -->
        <div class="d-flex jc-between px-2">
          <div class="d-flex aside-width">
            <div class="react-left ml-4 react-l-s">
              <span class="react-left"></span>
              <div class="text" @click="goIndex()">
                <dv-border-box-12><div style="width: 100px; text-align:center; cursor: pointer;">返回首页</div></dv-border-box-12>
              </div>
            </div>
          </div>

          <div class="d-flex aside-width">
            <div class="react-right mr-4 react-l-s">
              <span class="react-after"></span>
              <span class="text">{{ dateYear }} {{ dateWeek }} {{ dateDay }}</span>
            </div>
          </div>
        </div>

        <div class="body-box">
          <!-- 第三行数据 -->
          <div class="content-box">
            <div>
              <lCenter></lCenter>
            </div>
            <!-- 中间 -->
            <div>
              <center></center>
            </div>
            <!-- 中间 -->
            <div>
              <rCenter></rCenter>
            </div>
            <div>
              <rCenterone></rCenterone>
            </div>
          </div>

          <div class="bottom-box">
            <div>
              <leftCenter></leftCenter>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import drawMixin from "../utils/drawMixin"
import { formatTime } from '../utils/index.js'
import center from './homeComponents/center.vue'
import rCenter from './homeComponents/rCenter.vue'
import rCenterone from './homeComponents/rCenterone.vue'
import lCenter from './homeComponents/lCenter.vue'

export default {
  mixins: [drawMixin],
  components: {
    center,
    rCenter,
    rCenterone,
    lCenter
  },
  data() {
    return {
      loading: true,
      dateDay: null,
      dateYear: null,
      dateWeek: null,
      weekday: ['周日', '周一', '周二', '周三', '周四', '周五', '周六'],
      decorationColor: ['#568aea', '#000000'],
    }
  },
  mounted() {
    this.cancelLoading()
    this.timeFn()
  },
  methods: {
    // 返回首页
    goIndex() {
      this.$router.go(-1)
    },
    // 加载动画
    cancelLoading() {
      setTimeout(() => {
        this.loading = false
      }, 500)
    },
    // 实时时间
    timeFn() {
      this.timing = setInterval(() => {
        this.dateDay = formatTime(new Date(), 'HH: mm: ss')
        this.dateYear = formatTime(new Date(), 'yyyy-MM-dd')
        this.dateWeek = this.weekday[new Date().getDay()]
      }, 1000)
    },
  }
}
</script>

<style lang="scss">
@import '../assets/scss/home.scss';
</style>