<template>
  <div>
    <Echart
      :options="options"
      id="centreLeft1Chart"
      height="300px"
      width="410px"
    ></Echart>
  </div>
</template>

<script>
import Echart from '@/common/echart'
export default {
  data() {
    return {
      options: {},
    }
  },
  components: {
    Echart,
  },
  props: {
    cdata: {
      type: Object,
      default: () => ({}),
    },
  },
  watch: {
    cdata: {
      handler(newData) {
        this.options = {
          tooltip: {
            trigger: 'item',
          },
          legend: {
            top: '1%',
            left: 'center',
          },
          color: ['#ff7070', '#5c7bd9','#ffdc60','#7ed3f4','#9fe080'], 
          series: [
            {
              name: '预警处置',
              type: 'pie',
              radius: ['40%', '70%'],
              avoidLabelOverlap: false,
              itemStyle: {
                borderRadius: 15,
                borderColor: '#fff',
                borderWidth: 2,
              },
              label: {
                show: false,
                position: 'center',
              },
              emphasis: {
                label: {
                  show: true,
                  fontSize: 18,
                  fontWeight: 'bold',
                },
              },
              labelLine: {
                show: false,
              },
              data: newData,
            },
          ],
        }
      },
      immediate: true,
      deep: true,
    },
  },
}
</script>

<style lang="scss" scoped></style>
